import React, { useEffect, useState } from 'react';
import grained from './grained';

const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const generateGradientColors = () => {
  return Array.from({ length: 5 }, () => getRandomColor());
};

const GrainBackdrop = ({ animateColors = true, fixedColors = [] }) => {
  const [colors, setColors] = useState(animateColors ? generateGradientColors() : fixedColors);
  const [speed, setSpeed] = useState(8); // speed in seconds

  useEffect(() => {
    grained('#myElement', {
      animate: true,
      patternWidth: 100,
      patternHeight: 100,
      grainOpacity: 0.05,
      grainDensity: 1,
      grainWidth: 1,
      grainHeight: 1,
      grainChaos: 0.5,
      grainSpeed: 20
    });

    if (animateColors) {
      let animationFrameId;
      let lastUpdateTime = Date.now();

      const updateColors = () => {
        const now = Date.now();
        if (now - lastUpdateTime >= speed * 1000) {
          setColors(generateGradientColors());
          lastUpdateTime = now;
        }
        animationFrameId = requestAnimationFrame(updateColors);
      };

      updateColors();

      return () => cancelAnimationFrame(animationFrameId);
    }
  }, [animateColors, colors, speed]);

  useEffect(() => {
    const element = document.getElementById('myElement');
    colors.forEach((color, index) => {
      element.style.setProperty(`--color-${index}`, color);
    });
  }, [colors]);

  const grainContainerStyle = {
    width: '100vw',
    height: '100vh',
    position: 'relative',
    background: `
      radial-gradient(at 37% 28%, var(--color-0) 0px, transparent 50%),
      radial-gradient(at 12% 7%, var(--color-1) 0px, transparent 50%),
      radial-gradient(at 64% 80%, var(--color-2) 0px, transparent 50%),
      radial-gradient(at 99% 3%, var(--color-3) 0px, transparent 50%),
      radial-gradient(at 70% 63%, var(--color-4) 0px, transparent 50%)
    `
  };

  return (
    <div>
      <style>
        {`
          @property --color-0 {
            syntax: '<color>';
            inherits: false;
            initial-value: ${colors[0]};
          }
          @property --color-1 {
            syntax: '<color>';
            inherits: false;
            initial-value: ${colors[1]};
          }
          @property --color-2 {
            syntax: '<color>';
            inherits: false;
            initial-value: ${colors[2]};
          }
          @property --color-3 {
            syntax: '<color>';
            inherits: false;
            initial-value: ${colors[3]};
          }
          @property --color-4 {
            syntax: '<color>';
            inherits: false;
            initial-value: ${colors[4]};
          }

          #myElement {
            transition: --color-0 ${speed}s ease-in-out,
                        --color-1 ${speed}s ease-in-out,
                        --color-2 ${speed}s ease-in-out,
                        --color-3 ${speed}s ease-in-out,
                        --color-4 ${speed}s ease-in-out;
               -webkit-transition: --color-0 ${speed}s ease-in-out,
                        --color-1 ${speed}s ease-in-out,
                        --color-2 ${speed}s ease-in-out,
                        --color-3 ${speed}s ease-in-out,
                        --color-4 ${speed}s ease-in-out;
          }
        `}
      </style>
      <div id="myElement" style={grainContainerStyle}></div>
    </div>
  );
};

export default GrainBackdrop;