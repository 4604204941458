import React from 'react';
import { Link } from 'react-router-dom';
import logo from './logo.png';
import './index.css';
import LorenzAttractor from './LorenzAttractor';
import GrainBackdrop from './GrainBackdrop';

function App() {
  const arr0 = ['#fcf4d9', '#8ed2c9', '#00aaa0', '#d55b3e', '#F0A500'];
  const arr1 = ['#F9D689', '#FEFFD2', '#FFBF78', '#FFEEA9', '#F0A500'];
  const arr2 = ['#FEA1A1', '#ECCDB4', '#F0EDD4', '#F9FBE7', '#FF7D29'];

  return (
    <div className="relative">
      <div className="absolute top-0 left-0 w-full h-full z-0">
        <GrainBackdrop animateColors={false} fixedColors={arr0} />
      </div>
      <div className="relative z-10 text-center">
        <header className="min-h-screen flex flex-col items-center justify-center text-white">
          <div className='fixed top-0 left-0 text-black flex justify-between w-full'>
            <img src={logo} className="p-5 w-full max-w-[150px] sm:max-w-[160px] pointer-events-none" alt="logo" />
            <div className='flex font-bold font-helixa-regular mt-6 mr-3'>
              <Link className='px-2'>Shop</Link>
              <Link className='px-2'>About</Link>
            </div>
          </div>

          <p className='px-5 text-slate-900 text-5xl sm:text-5xl md:text-6xl lg:text-7xl mt-5 font-helixa-black'>
            The modern personal tailor.
          </p>
          <div className='flex space-x-4 mt-16 text-xl sm:text-2xl'>
            <Link to="/scan" className="relative btn-slide-show font-helixa-regular px-10 md:px-20">
              <p className="btn-slide-show-text1">Let's go!</p>
              <div className="btn-slide-show-inner">
                <p className="btn-slide-show-text2">Scan →</p>
              </div>
            </Link>
            <Link
              className="text-black hover:text-white/70 px-10 md:px-20 py-2"
              to="/partners"
            >
              Our Partners
            </Link>
          </div>
        </header>
      </div>
    </div>
  );
}

export default App;