import React from 'react';

function Scan() {
  return (
    <div>
      <h1>This is a big change.</h1>
    </div>
  );
}

export default Scan;